@font-face {
	font-family: "MyiaTinyMCEcons";
	font-display: block;
	src: url("/fonts/MyiaTinyMCEcons.c9129caa04d771258bcab4f6e4e8b33e.eot?#iefix") format("embedded-opentype"),
url("/fonts/MyiaTinyMCEcons.0177dc3af503ff0910bbf79c53218f2c.woff2") format("woff2"),
url("/fonts/MyiaTinyMCEcons.a69d1d371829be37febf4aa227012bf6.woff") format("woff"),
url("/fonts/MyiaTinyMCEcons.3cf586f05b0b7f4aa9676aaf1fa6aa00.ttf") format("truetype"),
url("/fonts/MyiaTinyMCEcons.7dfdc0622dfbae1105d0e3265cd3b8a0.svg#MyiaTinyMCEcons") format("svg");
}

.mce-ico- {
	line-height: 1;
}

.mce-ico-:before {
	font-family: MyiaTinyMCEcons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.mce-i-crop:before {
	content: "\f101";
}
.mce-i-image:before {
	content: "\f102";
}
.mce-i-insertRowAfter:before {
	content: "\f103";
}
.mce-i-insertRowBefore:before {
	content: "\f104";
}
.mce-i-removeBlock:before {
	content: "\f105";
}
.mce-i-removeRow:before {
	content: "\f106";
}
